<template>
  <v-dialog v-model="dialogDelete" max-width="600px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container fluid>
            <v-row class="pa-0">
              <v-col cols="12" class="pa-0 py-4">
                <span class="display-2">
                  <v-icon left class="pb-1">mdi-apple-finder</v-icon
                  >{{ $vuetify.lang.t("$vuetify.cancelReason") }}</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-0" cols="12" lg="12" md="6" sm="12">
                <v-textarea
                  :label="$vuetify.lang.t('$vuetify.cancelReason')"
                  v-model="deleteObj.cancel_reason"
                  outlined
                  height="120"
                  :rules="generalRule"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          outlined
          @click="$emit('update:dialogDelete', false)"
          >{{ $vuetify.lang.t("$vuetify.cancel") }}</v-btn
        >
        <v-spacer />
        <v-btn color="red darken-1" @click="validate">{{
          $vuetify.lang.t("$vuetify.confirm")
        }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "abolishStockOutDialog",
  props: { dialogDelete: Boolean, deleteObj: Object },
  data() {
    return {
      valid: false,
      generalRule: [(v) => !!v || "this field is required"],
    }
  },
  methods: {
    validate() {
      console.log("Validate", this.deleteObj);
      this.$refs.form.validate() == true ? this.$emit("handleDelete", this.deleteObj) : console.log("false");
    },
  }
};
</script>